import React from "react"

import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import SolutionForm from "./solutionForm"

import styled from "styled-components"

const Wrapper = styled.div`
  width: 100%;
  display: inline-grid;
  grid-template-columns: 110px 110px 110px 110px 110px;
  grid-gap: 10px;
  grid-template-rows: 110px 110px 110px 110px 110px;
  padding: 0;

  @media (max-width: 700px) {
    grid-template-columns: 90px 90px 90px 90px 90px;
    grid-template-rows: 90px 90px 90px 90px 90px;
  }

  @media (max-width: 650px) {
    grid-template-columns: 80px 80px 80px 80px 80px;
    grid-template-rows: 80px 80px 80px 80px 80px;
  }

  @media (max-width: 515px) {
    grid-template-columns: 70px 70px 70px 70px 70px;
    grid-template-rows: 70px 70px 70px 70px 70px;
  }

  @media (max-width: 415px) {
    grid-template-columns: 50px 50px 50px 50px 50px;
    grid-template-rows: 50px 50px 50px 50px 50px;
  }

  .box {
    background-color: lightgrey;
    color: #fff;
    border-radius: 5px;
    font-size: 150%;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
    &:focus {
      border-color: #222;
      box-shadow: none;
    }
    @media (max-width: 400px) {
      font-size: 1rem;
      padding: 15px;
    }
  }
  .clicked {
    background-color: #222;
  }
`

const MatrixBingo = props => {
  const handleClick = e => {
    e.target.classList.toggle("clicked")
  }

  const boxes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]

  return (
    <>
      <Container>
        <Grid justify="center" container>
          <div>
            <Wrapper>
              {boxes.map(box => (
                <div
                  className="box"
                  key={box}
                  boxid={box}
                  onClick={e => handleClick(e, box)}
                  onKeyDown={() => {
                    return
                  }}
                  role="button"
                  aria-label="Boton de matriz"
                  tabIndex={box}
                >
                  {box}
                </div>
              ))}
            </Wrapper>
          </div>
        </Grid>
      </Container>

      <SolutionForm data={props.data} next={props.next}></SolutionForm>
    </>
  )
}

export default MatrixBingo
