import React, { useState, useEffect } from "react"
import AlertSuccess from "../alerts/alertSuccess"
import ContinueButton from "../buttons/continueButton"
import Switch from "@material-ui/core/Switch"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import { withStyles } from "@material-ui/core"

const Switch2 = withStyles({
  switchBase: {
    color: "#363636",
    "&$checked": {
      color: "#363636",
    },
    "&$checked + $track": {
      backgroundColor: "white",
    },
  },
  checked: {},
  track: {
    backgroundColor: "white",
  },
})(Switch)

const Switches = props => {
  const [checked, setChecked] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
  })

  const [success, setSuccess] = useState(false)

  useEffect(() => {
    let solutionParsed = JSON.parse(props.data.acf.solution)
    if (JSON.stringify(checked) === JSON.stringify(solutionParsed)) {
      setSuccess(true)
    }
  }, [props.data.acf.solution, checked])

  const handleChange = (e, i) => {
    setChecked({
      ...checked,
      [i]: e.target.checked,
    })
  }

  return (
    <Container>
      <Grid justify="center" container>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <Switch2
            onChange={e => handleChange(e, 1)}
            color="warning"
            disabled={success === true ? true : false}
          />
          <Switch2
            onChange={e => handleChange(e, 2)}
            disabled={success === true ? true : false}
          />
        </Grid>
      </Grid>
      <Grid justify="center" container>
        <Grid item className="switches" lg={2} md={2} sm={2} xs={6}>
          <Switch2
            onChange={e => handleChange(e, 3)}
            disabled={success === true ? true : false}
          />
          <Switch2
            onChange={e => handleChange(e, 4)}
            disabled={success === true ? true : false}
          />
        </Grid>
      </Grid>
      <Grid justify="center" container>
        <Grid item className="switches" lg={2} md={2} sm={2} xs={6}>
          <Switch2
            onChange={e => handleChange(e, 5)}
            disabled={success === true ? true : false}
          />
          <Switch2
            onChange={e => handleChange(e, 6)}
            disabled={success === true ? true : false}
          />
        </Grid>
      </Grid>
      <Grid justify="center" container>
        <Grid item lg={12}>
          {success === true ? <AlertSuccess data={props.data} /> : ""}
          {success === true ? <ContinueButton next={props.next} /> : ""}
        </Grid>
      </Grid>
    </Container>
  )
}

export default Switches
