import firebase from "firebase/app"
import "firebase/firestore"

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyB3eLN6t377-7RZNVVOTDTK96RDG0g73QA",
  authDomain: "instituto-canario-de-igualdad.firebaseapp.com",
  databaseURL: "https://instituto-canario-de-igualdad.firebaseio.com/",
  projectId: "instituto-canario-de-igualdad",
  storageBucket: "instituto-canario-de-igualdad.appspot.com",
  messagingSenderId: "687117865272",
  appId: "1:687117865272:web:e4daee676e0284a1bc31cd",
  measurementId: "G-YTJDHLMJCF",
}

// Initialize Firebase
const fb = firebase.initializeApp(firebaseConfig)

export const db = fb.firestore()
