import React, { useState } from "react"
import styled from "styled-components"
import AlertSuccess from "../alerts/alertSuccess"
import ContinueButton from "../buttons/continueButton"
import { theme } from "../layout/theme"

import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"

const Wrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  display: inline-grid;
  grid-template-columns: 110px 110px 110px 110px;
  grid-gap: 10px;
  grid-template-rows: 110px 110px 110px 110px;
  padding: 0;

  @media (max-width: 700px) {
    grid-template-columns: 90px 90px 90px 90px;
    grid-template-rows: 90px 90px 90px 90px;
  }

  @media (max-width: 650px) {
    grid-template-columns: 80px 80px 80px 80px;
    grid-template-rows: 80px 80px 80px 80px;
  }

  @media (max-width: 515px) {
    grid-template-columns: 70px 70px 70px 70px;
    grid-template-rows: 70px 70px 70px 70px;
  }

  @media (max-width: 415px) {
    grid-template-columns: 50px 50px 50px 50px;
    grid-template-rows: 50px 50px 50px 50px;
  }

  .box {
    background-color: lightgrey;
    color: #fff;
    border-radius: 5px;
    font-size: 150%;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
  .green {
    background-color: ${theme.palette.success.main};
  }
  .red {
    background-color: ${theme.palette.error.main};
  }
`

const Matrix = props => {
  const [active, setActive] = useState("")
  const [success, setSuccess] = useState()

  const steps = props.data.acf.solution

  const boxes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]
  let count = 0
  const handleClick = (e, box) => {
    const parsedSteps = JSON.parse(steps)

    e.preventDefault()
    if (Number(box) === Number(parsedSteps[count])) {
      count++
      e.target.classList.add("green")
    } else if (parsedSteps[count] === "end") {
      e.target.classList.add("green")
      setSuccess(true) // Esto se ejecuta cuando se termina la matriz
    } else {
      count = 0
      setActive("red")
      setTimeout(() => {
        setActive("")
      }, 1000)
    }
  }

  return (
    <Container>
      <Grid container justify="center">
        {success === true ? (
          <Grid item lg={8}>
            <AlertSuccess data={props.data} />
            <ContinueButton next={props.next} />
          </Grid>
        ) : (
          ""
        )}
        <div>
          {success === true ? (
            ""
          ) : (
            <Wrapper>
              {boxes.map(box => (
                <div
                  className={active === "red" ? "box red" : "box"}
                  key={box}
                  boxid={box}
                  onClick={e => handleClick(e, box)}
                  onKeyDown={() => {
                    return
                  }}
                  role="button"
                  aria-label="Boton de matriz"
                  tabIndex={box}
                ></div> //Invisible character inside the div
              ))}
            </Wrapper>
          )}
        </div>
      </Grid>
    </Container>
  )
}

export default Matrix
