import React, { useState } from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"

const ModalProgress = props => {
  const [show, setShow] = useState(true)

  // Si selecciona empezar de nuevo, borramos el progreso y el index de localstorage
  const startOver = () => {
    localStorage.removeItem("progress")
    localStorage.removeItem("index")
    window.location.reload()
    setShow(false)
  }

  // Si selecciona continuar, simplemente quitamos el modal y llamamos a una funcion en challenge,
  // que a su vez llama a una funcion que devuelve el usuario a donde estaba antes

  const resumeGame = () => {
    props.handleModal()
    setShow(false)
  }

  const handleClose = () => {
    console.log("nothing")
  }

  return (
    <>
      <Dialog
        open={show}
        onClose={handleClose}
        scroll="body"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ overflowY: "scroll" }}
      >
        <DialogTitle id="alert-dialog-title">
          Ups, ha pasado algo...
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Deseas empezar desde el principio o continuar desde donde lo
            dejaste?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={startOver}>
            Empezar de nuevo
          </Button>
          <Button variant="outlined" onClick={resumeGame}>
            Continuar juego
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ModalProgress
